html {
  /* forces page height to equal inner window height. */
  min-height: 100%;
  overflow-x: hidden;
}

body {
  /* background-image: linear-gradient(black 0%, #21162d 60%); */
  background-color: var(--background_color);
  overscroll-behavior: none;
}

:root {
  /* old green */
  /* --color: #33B18A */
  --background_color: #1C232E;
  --color: #3AAFC9;
  --font: 'Raleway', 'Open Sans', sans-serif;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.text-gradient {
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(90deg, #7997C7, #9b79c7);
  background-size: 0% 3px;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 1.5s ease;
  cursor: pointer;
}

.text-gradient:hover {
  transition: background-size 300ms ease;
  color: inherit;
  background-size: 100% 100% !important;
}

.text-color {
  color: var(--color);
}

.scroll-trigger-container {
  position: relative;
}

/* ----------------------------- Header ----------------------------- */

#navbar {
  display: none;
  min-height: 100px !important;
  /* z-index: 1; */
  background-color: transparent !important;

}

.container-fluid {
  background-color: transparent !important;
}

.navbar-brand {
  padding-left: 3%;
  font-size: 22px;
  font-weight: 600;
}

.nav-link {
  font-size: 18px;
  display: inline-block;
  position: relative;
  color: var(--color);
  font-weight: 400;
  cursor: pointer;
}

.text_cursor {
  color: var(--color);
}

/* menu hamburger button*/

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51, 177, 138, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: var(--color);
}

/* underline text hover effect */

.nav-link:after, .navbar-brand:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-link:hover:after, .navbar-brand:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* ----------------------------- Overlay ----------------------------- */

#Loadingoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  transition: 1s;
}

#loading-line {
  position: absolute;
  top: 50%;
  bottom: 50%;
  border-width: 0;
  height: 3px;
  color: var(--color);
  background-color: var(--color);
  width: 100%;
  animation-name: loading;
  animation-duration: 1s;
  z-index: 101;
}

#LoadingPercentage {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 100;
  font-size: 110px;
  color: #2c2b2b;
  font-family: Arial, Helvetica, sans-serif;
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%
  }
}

/* ----------------------------- Masthead ----------------------------- */

#top {
  position: absolute;
  inset: 0;
  visibility: none;
}

#Home {
  margin-top: -100px;
}

#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.title-container {
  position: absolute;
  top: 42%;
  left: 8%;
  max-width: 92vw;
}

.title-name {
  font-family: var(--font);
  font-size: 70px;
  font-weight: 600;
  color: white;
}

.title-type {
  font-family: var(--font);
  font-size: 28px;
  font-weight: 500;
  color: var(--color);
}

#masthead {
  width: 100%;
  height: 100vh;
}

/* ----------------------------- background ----------------------------- */
#background {
  display: none;
  width: 100vw;
}

#backgroundcanvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-arrow-box {
  display: flex;
  flex-direction: row;
}

.background-text-arrow {
  font-family: var(--font);
  font-size: 30px;
  font-weight: 600;
  color: white;
  margin: auto auto auto 0;
  padding-left: 10px;
  padding-bottom: 1rem;
}

/* .background-outline-text {
  outline: 2px dashed grey;
}

.background-border-text {
  border: 2px dashed grey;
} */

#rocket-mouse {
  position: absolute;
  color: var(--color);
  font-size: 2rem;
}

#extra-background p{
  position: absolute;
  color: white;
  font-family: var(--font);
  font-size: 40px;
}

#extra-background-1{
top: 10%;
left: 5%;
}

#extra-background-2{
  top: 18%;
  right: 20%;
}

#extra-background-3{
  bottom: 30%;
  right: 3%;
}

#extra-background-4{
 bottom: 3%;
 left: 3%; 
}

/* ----------------------------- about ----------------------------- */
#about {
  width: 100%;
  /* height: fit-content; */
}

.heading {
  padding-top: 100px;
  margin: 0px auto 0px auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.line {
  display: block;
  width: 45%;
  border-top: 2px solid var(--color);
  margin: auto;
}

.heading .title {
  width: fit-content;
  font-family: var(--font);
  color: white;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}

.about-body {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.about-text {
  width: 60%;
  margin: auto 0px auto 0px;
}

.icons a {
  font-size: 2rem;
  color: white;
}

.icons a i {
  margin-left: 10px;
  transition: 0.7s;
}

.icons a i:hover {
  transform: scale(1.2);
}

.fa-linkedin:hover {
  color: #0077B5;
}

.fa-github:hover {
  color: #7F278F;
}

.about-introduction {
  color: white;
  font-size: 60px;
  font-weight: 500;
  font-family: var(--font);
  margin-bottom: 0px !important;
  line-height: 0.8;
}

.about-name {
  color: var(--color);
}

.about-title {
  color: rgb(189, 189, 189);
  font-size: 25px;
  font-weight: 300;
}

.about-description {
  color: white;
  font-size: 25px;
  font-weight: 300;
  font-family: var(--font);
}

.resume-photo-container {
  width: 25%;
  margin: auto 0px auto 0px;
  text-align: center;
  position: relative;
  display: block;
}

#resume-photo {
  padding: 0px 10px 0px 10px;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .about-introduction {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {

  .about-text {
    padding-top: 20px;
  }

  .about-introduction {
    font-size: 25px;
  }

  .about-title {
    font-size: 25px;
  }

  .about-description {
    font-size: 16px;
  }

  .resume-photo-container {
    width: 60%;
    min-height: 250px;
  }

  #resume-photo {
    height: 250px;
    width: auto;
  }

  #firefox-scrolltrigger-image-filler {
    height: 250px;
    visibility: hidden;
    position: absolute;
    inset: 0;
  }
  
}

/* ----------------------------- Skills ----------------------------- */
.skill-name {
  color: white;
  font-family: var(--font);
  font-size: 20px;
  font-weight: 300;
}

.skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.about-skills {
  width: 40%;
}

.skill-box-container {
  width: 100%;
  height: 40px;
  position: relative;
  top: -42.5px;
}

.skill-percentage {
  color: var(--color);
  font-family: var(--font);
  font-size: 15px;
  font-weight: 500;
  width: fit-content;
  position: relative;
  left: 0;
  padding: 5px;
  background: #eee;
  margin-bottom: 12px !important;
  transition: 1.5s;
}

.skill-percentage:after {
  position: absolute;
  content: "";
  top: 20px;
  left: 10px;
  border-width: 10px 0px 15px 10px;
  border-color: transparent #eee;
  border-style: solid;
}

.skill-box {
  background-color: rgb(167, 167, 167);
  height: 20px;
  border-radius: 7px;
}

.skill-color {
  background-color: var(--color);
  width: 0%;
  height: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  transition: 1.5s;
}

@media only screen and (max-width: 767px) {
  .about-skills {
    width: 80%;
  }
}

/* ----------------------------- Experience ----------------------------- */

.experience-body, .certificate-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.education, .work {
  height: fit-content;
  width: 40%;
  margin-top: 20px;
}

.experience-titlebox {
  font-family: var(--font);
  font-size: 30px;
  font-weight: 600;
  color: white;
  width: fit-content;
  padding: 20px;
  border: 2px solid var(--color);
  margin-bottom: 60px;
}

.experience-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.experience-circle {
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: var(--color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

.experience-no {
  margin: auto;
  font-family: var(--font);
  font-size: 20px;
  font-weight: 300;
  color: white;
  width: fit-content;
}

.experience-text {
  font-family: var(--font);
  position: relative;
  top: -23px;
  left: 5px;
  padding-bottom: 30px;
}

.experience-date {
  color: var(--color);
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 7px !important;
}

.experience-title {
  color: white;
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 10px !important;
  line-height: 1;
}

.experience-subtitle {
  color: var(--color);
  font-size: 20px;
  margin-bottom: 0 !important;
}

.experience-description {
  color: rgb(189, 189, 189);
  margin-bottom: 0 !important;
}

.experience-website {
  color: white;
}

.experience-branch {
  border-left: 2px solid var(--color);
  position: relative;
  transform: scaleY(0);
  transform-origin: top;
  left: 20px;
  transition: 1.5s;
}

@media only screen and (max-width: 767px) {
  .education, .work {
    width: 80%;
  }
}

/* ----------------------------- Certificates ----------------------------- */
.experience-certificates-container .heading .title {
  padding-bottom: 30px;
}

.certificate-education, .certificate-course {
  height: fit-content;
  width: 40%;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .certificate-education, .certificate-course {
    width: 80%;
  }
}

/* ----------------------------- Project ----------------------------- */

#project {
  overflow-x: hidden;
  position: relative;
}

#project-body {
  overscroll-behavior: none;
  overflow-x: hidden;
  height: 100vh;
}

#project-list {
  overscroll-behavior: none;
  width: 100vw;
  height: 50vh;
  position: relative;
  margin-top: 25vh;
  z-index: 10;
}

.project-showcase {
  height: fit-content;
  width: 30vw;
  position: absolute;
  inset: 0;
  text-align: center;
  background-color: #3e557a;
  border-radius: 3%;
  transition: 0.5s;
}

.project-showcase .image-container {
  margin: 10px auto 0px auto;
  width: 80%;
  overflow: hidden;
  position: relative;
}

.project-showcase h2 {
  color: var(--color);
  font-size: 25px;
  font-weight: 500;
  margin: 20px auto 10px auto !important;
  width: 80%;
  line-height: 1;
}

.project-showcase .image-container img {
  transition: transform .5s ease;
}


.project-showcase .image-container p {
  position: absolute;
  bottom: 1%;
  right: 5%;
  background-color: var(--color);
  color: white;
  font: var(--font);
  font-weight: 500;
  padding: 5px;
  border-radius: 10%;
}

.project-showcase:hover>.image-container img {
  transform: scale(1.5);
}

.project-showcase:hover {
  cursor: pointer;
}

.project-background {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background-color: yellow;
  opacity: 0;
}

.project-background img {
  object-fit: cover;
}

#project-0-background {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {

  .project-showcase {
    width: 50vw;
  }
}

/* ----------------------------- Contact me ----------------------------- */

.contact-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-title {
  font-family: var(--font);
  font-size: 60px;
  font-weight: 300;
  color: white;
  width: 35%;
  margin: auto 0 auto 0;
  text-align: center;
}

.contact-form {
  background-color: #162947;
  height: fit-content;
  width: 40%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.form-field {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  width: 100%;

  padding: 2px;
  border-radius: 4px;
  border-bottom: 2px solid var(--color);

  transition-property: border;
  transition-delay: 0.5s;
}

.form-field-div {
  position: relative;
  margin: 20px;
}

.form-field-animation-class::before {
  border-radius: 4px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  animation: form-input-animation 1s forwards 0s;
  width: 100%;
  z-index: 3;
  border-bottom: 2px solid var(--color);
  border-left: 2px solid var(--color);
  transform-origin: 0 100% 0;
}

.form-field-animation-class::after {
  border-radius: 4px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  animation: form-input-animation 1s forwards 0s;
  width: 100%;
  z-index: 3;
  border-right: 2px solid var(--color);
  border-top: 2px solid var(--color);
  transform-origin: 100% 0 0;
}

@keyframes form-input-animation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.form-field:focus {
  outline: none;
}

.form-submit {
  margin: auto;
  background-color: var(--color);
  width: 40%;
  color: white;
  font-family: var(--font);
  font-size: 20px;
  font-weight: 300;
  border: none;
  border-radius: 10px;
  transition: 0.5s;
}

.form-submit:hover {
  transform: scale(1.1);
}

.checkbox{
  width: auto;
  margin: 0 7px;
}

label{
  color: rgb(189, 189, 189);
  font-size: 22px;
  font-weight: 300;
  display: inline;
}

@media only screen and (max-width: 767px) {
  .contact-title {
    width: 80%;
    font-size: 25px;
    padding-bottom: 10px;
  }

  .contact-form {
    width: 80%;
  }
}


/* ----------------------------- View Project ----------------------------- */
#Color-background {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: var(--color);
}

#View-Project {
  min-height: 100vh;
  height: fit-content;
  width: 100vw;
  position: fixed;
  inset: 0;
  background-color: var(--background_color);
  z-index: 101;
}

#View-Project .image-container {
  height: fit-content;
  width: 60%;
  margin: 0 auto 0 auto;
}

#View-Project img {
  object-fit: cover;
}

#View-Project button {
  position: fixed;
  top: 5%;
  right: 5%;
  z-index: 2000;
  background-color: transparent;
  color: white;
  font-size: 50px;
  border: none;
  font-family: var(--font);
  transition: 1s;
}

#View-Project button:hover {
  transform: rotate(360deg);
}

#View-Project .heading {
  padding-top: 60px !important;
}

#View-Project .tags {
  color: white;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px !important;
  position: relative;
  border-left: 3px solid var(--color);
  padding: 5px 5px 5px 30px;
  font-family: var(--font);
}

#View-Project .description {
  color: rgb(189, 189, 189);
  font-size: 22px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px !important;
  font-family: var(--font);
}

#View-Project .project-text {
  width: 60%;
  margin: 0 auto 0 auto;
}